.education-heading {
  font-size: 56px;
  font-weight: 400;
}

.education-main-div {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.education-text-div {
  margin-left: 50px;
  padding: 125px 0px;
}

.education-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.education-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 20px 0px;
  margin: 0px auto;
  margin-top: 2rem;
}

.education-section > * {
  flex: 1;
  margin-bottom: 30px;
}

.education-card-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.education-image-div > img {
  max-width: 100%;
  height: auto;
}

.education-heading {
  font-size: 56px;
  font-weight: 400;
}


/* Media Query */
@media (max-width: 1380px) {
  .education-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .education-heading {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .education-main-div {
    flex-direction: column;
  }
  .education-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .education-text {
    font-size: 16px;
  }
  .education-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .education-image-div {
    display: none;
    order: 2;
  }
}





@media (max-width: 1380px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 40px;
  }
  .education-text-subtitle {
    font-size: 18px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .education-card-container {
    padding-top: 0px;
  }
  .education-heading {
    font-size: 30px;
    text-align: center;
  }
  .education-text-subtitle {
    font-size: 16px;
  }
}
